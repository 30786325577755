<template>
  <KTPortlet :title="form.name" headSize="md">
    <template v-slot:body>
      <b-form @submit.prevent="onSubmit">
        <div class="row">
          <b-form-group label="Tên " class="col-7">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="form.name"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Tên viết tắt" class="col-5">
            <b-form-input type="text" v-model="form.shortName"></b-form-input>
          </b-form-group>
        </div>
        <b-form-group id="input-group-2" label="Địa chỉ" label-for="input-2">
          <b-form-input
            id="input-2"
            type="text"
            required
            v-model="form.address"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Telephone" label-for="input-3">
          <b-form-input
            id="input-3"
            type="text"
            v-model="form.telePhone"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Mobile phone"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            type="text"
            v-model="form.mobilePhone"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-3" label="Email" label-for="input-3">
          <b-form-input
            id="input-3"
            type="email"
            v-model="form.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-4" label="Manager" label-for="input-4">
          <!-- <b-form-select
            id="input-4"
            v-model="managerIdSelected"
            :options="listManager"
            value-field="_id"
            text-field="name"
          >
          </b-form-select> -->
          <v-select
            :options="listManager"
            label="name"
            v-model="managerIdSelected"
          >
            <template #search="{attributes, events}">
              <input
                class="vs__search"
                :required="!managerIdSelected"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <template v-slot:no-options="{ listManager }">
              <em v-if="!listManager">Không tìm thấy Manager nào</em>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group id="input-group-4" label="Trạng thái" label-for="input-4">
          <b-form-select
            id="input-4"
            v-model="form.status"
            :options="states"
            required
          >
          </b-form-select>
        </b-form-group>
        <b-form-group id="input-group-3" label="Website" label-for="input-3">
          <b-form-input
            id="input-3"
            type="text"
            v-model="form.website"
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" right variant="primary">Hoàn thành</b-button>
      </b-form>
    </template>
  </KTPortlet>
</template>

<style lang="scss"></style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { UserRole, Status } from "./../../constans";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import Loader from "@/common/loader";
import { getAllUsersManager } from "@/api/outlet";
import { updateSuccess } from "@/helper/index";

export default {
  name: "Edit",
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      states: [
        {
          value: Status.Actived,
          text: "Kích hoạt"
        },
        {
          value: Status.NotActived,
          text: "Chưa kích hoạt"
        }
      ],
      form: {
        name: "",
        shortName: "",
        address: "",
        website: "",
        telePhone: "",
        mobilePhone: "",
        email: "",
        managerId: "",
        status: Status.NotActived
      },
      listManager: [],
      managerIdSelected: "RG_Rmv8XoVcXhElJd2dj-"
    };
  },

  mounted() {},

  created() {
    this.$store.dispatch("updateListUsers").then(() => {
      const item = this.$router.history.current.params?.item;

      if (item) {
        this.form = { ...this.form, ...item };
        this.$router.replace({ params: { item: item._id } });
      }
      this.getListUser(item.managerId);
    });
  },

  methods: {
    onSubmit() {
      const query = {
        id: this.form._id,
        bodyParams: {
          address: this.form.address,
          name: this.form.name,
          shortName: this.form.shortName,
          website: this.form.website,
          status: this.form.status,
          managerId: this.managerIdSelected._id,
          email: this.form.email,
          mobilePhone: this.form.mobilePhone,
          telePhone: this.form.telePhone
        }
      };
      this.$store.dispatch("updateOutlet", query).then(() => {
        updateSuccess("outlet");
      });
    },

    getListUser(itemMagerId) {
      Loader.fire();
      getAllUsersManager().then(res => {
        const dataTemp = res.data.find(item => item._id === itemMagerId);
        this.managerIdSelected = dataTemp;
        this.listManager = res.data.filter(
          user => user.status === Status.Actived
        );
        Loader.close();
      });
    }
  }
};
</script>
